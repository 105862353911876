import React from 'react';
import { stateToColor } from '../themes';
import { ProjectRef, TaskId, WorkspaceId } from '../../core/model';
import TaskCheckbox from '../../layout/atoms/TaskCheckbox/TaskCheckbox';
import {
    plannedTaskAction,
    ProjectState,
    taskAction,
    TaskStateObject,
} from '../../store/workspaceSlice';
import { ReactComponent as DragIcon } from '../../assets/icons/drag-indicator.svg';
import { ReactComponent as MoveBackIcon } from '../../assets/icons/move-back.svg';
import { AppDispatch } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from '../../layout/atoms/Dropdown';
import Button from '../../layout/atoms/Button';
import { TaskName } from '../TaskName';

const PlannedTask = (props: {
    workspaceId: WorkspaceId;
    projectRef: ProjectRef;
    taskId: TaskId;
    projectName?: string;
    color?: string;
    dragHandleProps?: any;
}) => {
    const dispatch = useDispatch<AppDispatch>();

    const taskState: TaskStateObject = useSelector(
        (state: any) =>
            [
                ...state.workspace.tasks.filter(
                    (t: TaskStateObject) => t.id === props.taskId
                ),
                ...state.workspace.projects
                    .filter(
                        (p: ProjectState) => p.id === props.projectRef.projectId
                    )
                    .flatMap((p: ProjectState) =>
                        p.tasks.filter(
                            (t: TaskStateObject) => t.id === props.taskId
                        )
                    ),
            ][0]
    );

    const removeFromPlanned = () => {
        dispatch(
            taskAction({
                taskId: props.taskId,
                action: 'IDLE',
                command: {
                    workspaceId: props.projectRef.workspaceId,
                    projectId: props.projectRef.projectId,
                },
            })
        );
    };

    const taskClicked = (checked: boolean) => {
        dispatch(
            plannedTaskAction({
                taskId: props.taskId,
                action: checked ? 'COMPLETE' : 'REVERT',
                command: {
                    workspaceId: props.projectRef.workspaceId,
                    projectId: props.projectRef.projectId,
                },
            })
        );
    };

    return (
        <div className={'task-planned'}>
            <div
                className={'task-planned__indicator'}
                style={{ background: props.color }}
                {...props.dragHandleProps}
            >
                <DragIcon />
            </div>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    color: stateToColor(taskState.state),
                    flex: '1',
                    alignContent: 'center',
                }}
            >
                <span style={{ marginRight: '14px' }}>
                    <TaskCheckbox
                        checked={taskState.done}
                        onClick={taskClicked}
                    />
                </span>
                <TaskName name={taskState.name}></TaskName>
            </div>
            {!taskState.done && (
                <div>
                    <Dropdown position="bottomRight" horizontal={true}>
                        <Button onClick={removeFromPlanned}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '100vh',
                                }}
                            >
                                <MoveBackIcon style={{ display: 'inline' }} />
                                <span style={{ marginLeft: '6px' }}>
                                    Move&nbsp;to&nbsp;planning
                                </span>
                            </div>
                        </Button>
                    </Dropdown>
                </div>
            )}
            <div
                style={{
                    position: 'absolute',
                    bottom: '2px',
                    right: '24px',
                    fontSize: '0.75em',
                    color: props.color, // Assuming props.color is defined
                    whiteSpace: 'nowrap',
                    flexShrink: 0,
                }}
            >
                <span style={{ position: 'relative', bottom: '0px' }}>
                    {props.projectName}
                </span>
            </div>
        </div>
    );
};

export default PlannedTask;
