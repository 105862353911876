import { useSelector } from 'react-redux';
import { LayoutState } from '../../../store/layoutSlice';
import styles from './logo.module.scss';
import { ReactComponent as LogoIconOnLight } from '../../../assets/logo/hustle-gradient-on-light.svg';
import { ReactComponent as LogoIconOnDark } from '../../../assets/logo/hustle-gradient-on-dark.svg';

const Logo = (props: { center?: boolean }) => {
    const layoutState: LayoutState = useSelector((state: any) => state.layout);

    return (
        <div className={`${styles.wrap} ${props.center && styles.center}`}>
            {layoutState.theme === 'light' ? (
                <LogoIconOnLight />
            ) : (
                <LogoIconOnDark />
            )}
        </div>
    );
};

export default Logo;
