import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Input from '../layout/atoms/Input/Input';
import InputCode from '../layout/atoms/InputCode';
import Button from '../layout/atoms/Button';

import GmailIcon from '../assets/3rd-party/gmail.png';
import OutlookIcon from '../assets/3rd-party/outlook.png';
import AuthApi from '../api/auth-api';
import { AppDispatch } from '../store';
import { useDispatch } from 'react-redux';
import { readSession } from '../store/userSlice';
import Logo from '../layout/atoms/Logo';
import Container from '../layout/atoms/Container/Container';
import { ReactComponent as IllustrationMail } from '../assets/illustrations/mail.svg';

type Mode = 'GET_EMAIL' | 'EMAIL_SENT';

type CodeValidation = 'checking' | 'invalid' | 'expired';

export const LoginCode = (props: { mode: Mode }) => {
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();

    const [mode, setMode] = useState<Mode>(props.mode);
    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState<string>('');
    const [debugCode, setDebugCode] = useState<string | undefined>(undefined);

    const [code, setCode] = useState('');

    const [codeValidation, setCodeValidation] = useState<
        CodeValidation | undefined
    >(undefined);

    const handleEmailChange = (event: any) => {
        const value = event.target.value.toLowerCase().trim();
        setEmail(value);

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailPattern.test(value)) {
            setValidEmail(value);
        } else {
            setValidEmail('');
        }
    };

    const checkCode = (code: string) => {
        setCodeValidation('checking');
        return AuthApi.defaultApi()
            .useLoginCode({ email: validEmail, code: code })
            .then(
                (response) => {
                    setCodeValidation(undefined);
                    localStorage.setItem('hustleToken', response.accessToken);
                    return dispatch(readSession()).finally(() => {
                        setCodeValidation(undefined);
                        navigate('/');
                    });
                },
                (error) => {
                    setCode('');
                    setCodeValidation('invalid');
                }
            );
    };

    const handleCodeChange = (event: any) => {
        const value = event
            .toUpperCase()
            .trim()
            .replace(/[^A-Z0-9]/g, '');

        setCode(value);

        const codePattern = /^[A-Z0-9]{6}$/;
        if (codePattern.test(value)) {
            return checkCode(value);
        }
    };

    const doGenerateLoginCode = () => {
        AuthApi.defaultApi()
            .generateLoginCode({ email: validEmail })
            .then((response) => {
                setMode('EMAIL_SENT');
                setDebugCode(response.debugCode);
            });
    };

    return (
        <Container narrow>
            <Logo />
            {mode === 'EMAIL_SENT' ? (
                <>
                    <h2>Check your email for a code</h2>
                    <IllustrationMail />
                    <p>
                        We’ve sent a 6-character code to {validEmail}.<br />
                        The code expires shortly, so please enter it soon.
                    </p>
                    <div>
                        <InputCode
                            value={code}
                            handleChange={handleCodeChange}
                            maxLength={6}
                            placeholder="A1BCD2"
                        ></InputCode>
                        {codeValidation == 'checking' && (
                            <div>Checking code...</div>
                        )}
                        {codeValidation == 'invalid' && (
                            <div>That code wasn’t valid. Have another go!</div>
                        )}
                        {codeValidation == 'expired' && (
                            <div>
                                That code’s no longer valid.{' '}
                                <u>Request a new code</u> and try again.
                            </div>
                        )}
                    </div>
                    {debugCode && (
                        <>
                            <br />
                            <br />
                            <br />
                            <h4>Debug code:</h4>
                            <h1 style={{ color: 'red' }}>
                                {debugCode.substring(0, 3)}-
                                {debugCode.substring(3, 6)}
                            </h1>
                        </>
                    )}
                    <br />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            gap: '24px',
                        }}
                    >
                        <a
                            href={'https://mail.google.com/mail/u/0/'}
                            target="_blank"
                            rel="noreferrer"
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                textDecoration: 'none',
                                color: 'currentcolor',
                            }}
                        >
                            <img
                                src={GmailIcon}
                                alt="Icon"
                                style={{ marginRight: '8px', width: '20px' }}
                            />
                            <span>Open Gmail</span>
                        </a>
                        <a
                            href={'https://outlook.live.com/mail/0/inbox'}
                            target="_blank"
                            rel="noreferrer"
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                textDecoration: 'none',
                                color: 'currentcolor',
                            }}
                        >
                            <img
                                src={OutlookIcon}
                                alt="Icon"
                                style={{ marginRight: '8px' }}
                            />
                            <span>Open Outlook</span>
                        </a>
                    </div>
                    <h4>Can’t find the message? Check your spam folder!</h4>
                </>
            ) : (
                <></>
            )}
            {mode === 'GET_EMAIL' ? (
                <>
                    <h2>Login / Sign up</h2>
                    <p>
                        Please, provide your email
                        <br />
                        and we'll send you code to log in.
                    </p>
                    <Input
                        value={email}
                        handleChange={handleEmailChange}
                        maxLength={100}
                        placeholder="email address"
                    />
                    <div>
                        <br />
                        <Button
                            theme="primary"
                            onClick={doGenerateLoginCode}
                            disabled={!validEmail}
                        >
                            <p>Continue</p>
                        </Button>
                    </div>
                </>
            ) : (
                <></>
            )}
        </Container>
    );
};
