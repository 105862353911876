import { useNavigate } from 'react-router-dom';
import Button from '../layout/atoms/Button';
import Container from '../layout/atoms/Container/Container';
import Logo from '../layout/atoms/Logo';

export const LoginPage = (props: { onUserLoggedIn: any }) => {
    const navigate = useNavigate();

    return (
        <Container narrow>
            <Logo center />
            <div>
                <Button onClick={() => navigate('/login')}>
                    Log in with email
                </Button>
            </div>
        </Container>
    );
};
