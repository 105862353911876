import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import Api from "../api";
import {ReadSessionResponse} from "../api/user-model";
import {WorkspaceId} from "../core/model";

export interface UserState {
    data: {
        userId: string;
        workspaceId: WorkspaceId;
        userEmail: string;
    } | null;
    status: "IDLE" | "LOADING" | "SUCCEEDED" | "FAILED";
    error: string | null;
}

const initialState: UserState = {
    data: null,
    status: "IDLE",
    error: null,
};

export const readSession = createAsyncThunk(
    'user/readSession',
    async (): Promise<ReadSessionResponse> => await Api.defaultApi().readSession()
);

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        logout: (state: UserState) => {
            state.data = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(readSession.pending, (state) => {
                state.status = 'LOADING';
                state.error = null;
            })
            .addCase(readSession.fulfilled, (state, action) => {
                state.status = 'SUCCEEDED';
                state.data = {
                    userId: action.payload.userId,
                    workspaceId: action.payload.workspaceId as WorkspaceId,
                    userEmail: action.payload.email,
                };
            })
            .addCase(readSession.rejected, (state, action) => {
                state.status = 'FAILED';
                state.error = action.error.message || 'An error occurred';
            });
    },
});

export const {logout} = userSlice.actions;

export default userSlice.reducer;
