import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import axios from 'axios';
import { Environment } from '../Environment';
import {
    PlannedTaskAction,
    ProjectId,
    ProjectRef,
    TaskAction,
    TaskId,
    TaskState,
    WorkspaceId,
} from '../core/model';
import {
    AddProjectCommand,
    ReadArchivedProjectsResponse,
    RenameProjectCommand,
    UpdateProjectCommand,
} from '../api/project-model';
import Api from '../api';
import { ReadWorkspaceResponse } from '../api/workspace-model';
import { AddTaskCommand } from '../api/task-model';
import { RootState } from './index';

export type TaskStateObject = {
    id: string;
    name: string;
    planned: boolean;
    done: boolean;
    createdAt: string;
    completedAt: string | null;
    state: TaskState;
};

export type SharedInfoState = {
    workspaceId: WorkspaceId;
    ownerId: string;
};

export type ProjectState = {
    id: ProjectId;
    workspaceId: WorkspaceId;
    projectRef: ProjectRef;
    name: string;
    color: string | null;
    tasks: TaskStateObject[];
    shared: SharedInfoState | null;
    createdAt: string;
    updatedAt: string;
};

export type ArchivedProjectState = {
    id: ProjectId;
    name: string;
    color: string | null;
};

export interface WorkspaceState {
    id: string;
    projects: ProjectState[];
    archivedProjects: {
        projects: ArchivedProjectState[];
        status: string;
        error: string | null;
    };
    tasks: TaskStateObject[];
    archivedProjectsCount: number;
    plannedTasks: string[];
    status: string;
    error: string | null;
}

const initialState: WorkspaceState = {
    id: '',
    projects: [],
    archivedProjects: {
        projects: [],
        status: 'IDLE',
        error: null,
    },
    tasks: [],
    archivedProjectsCount: 0,
    plannedTasks: [],
    status: 'IDLE',
    error: null,
};

const headers = {
    headers: {
        Authorization: `Bearer ${localStorage.getItem('hustleToken')}`,
    },
};

export const fetchWorkspace = createAsyncThunk(
    'workspace/fetchWorkspace',
    async (workspaceId: string): Promise<ReadWorkspaceResponse> =>
        await Api.defaultApi().fetchWorkspace(workspaceId)
);

export const addProject = createAsyncThunk(
    'projects/addProject',
    async (command: AddProjectCommand, { rejectWithValue }) => {
        try {
            return Api.defaultApi().addProject(command);
        } catch (err: any) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    }
);

export const renameProject = createAsyncThunk(
    'workspace/renameProject',
    async (
        props: { projectId: TaskId; command: RenameProjectCommand },
        { rejectWithValue }
    ) => {
        try {
            return Api.defaultApi()
                .renameProject(props.projectId, props.command)
                .then((response) => {
                    return { request: props, response: '' };
                });
        } catch (err: any) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    }
);

export const reorderProject = createAsyncThunk(
    'workspace/reorderProject',
    async (props: {
        workspaceId: string;
        projectId: string;
        itemIndex: number;
        index: number;
    }) => {
        const response = await axios.put(
            `${Environment.backendUrl}/api/v1/workspaces/${props.workspaceId}/move-project`,
            {
                projectId: props.projectId,
                index: props.index,
            },
            headers
        );

        return {
            itemIndex: props.itemIndex,
            index: props.index,
        };
    }
);

export const fetchArchivedProjects = createAsyncThunk(
    'workspace/fetchArchivedProjects',
    async (workspaceId: string): Promise<ReadArchivedProjectsResponse> =>
        await Api.defaultApi().fetchArchivedProjects(workspaceId)
);

export const archiveProject = createAsyncThunk(
    'workspace/archiveProject',
    async (projectId: string) => {
        const response = await axios.post<void>(
            `${Environment.backendUrl}/api/v1/projects/${projectId}/archive`,
            undefined,
            headers
        );

        return projectId;
    }
);

export const unarchiveProject = createAsyncThunk(
    'workspace/unarchiveProject',
    async (projectId: string) => {
        const response = await axios.post<ProjectState>(
            `${Environment.backendUrl}/api/v1/projects/${projectId}/unarchive`,
            undefined,
            headers
        );

        return response.data;
    }
);

export const deleteProject = createAsyncThunk(
    'workspace/deleteProject',
    async (projectId: string) => {
        const response = await axios.delete<void>(
            `${Environment.backendUrl}/api/v1/projects/${projectId}`,
            headers
        );

        return projectId;
    }
);

export const addTask = createAsyncThunk(
    'tasks/addTask',
    async (command: AddTaskCommand, { rejectWithValue }) => {
        try {
            return Api.defaultApi()
                .addTask(command)
                .then((response) => {
                    return { request: command, response: response };
                });
        } catch (err: any) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    }
);

export const deleteTask = createAsyncThunk(
    'tasks/deleteTask',
    async (
        props: { taskId: TaskId; command: UpdateProjectCommand },
        { rejectWithValue }
    ) => {
        try {
            return Api.defaultApi()
                .deleteTask(props.taskId, props.command)
                .then((response) => {
                    return { request: props, response: '' };
                });
        } catch (err: any) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    }
);

export const taskAction = createAsyncThunk(
    'tasks/planTask',
    async (
        props: {
            taskId: TaskId;
            action: TaskAction;
            command: UpdateProjectCommand;
        },
        { rejectWithValue }
    ) => {
        try {
            return Api.defaultApi()
                .taskAction(props.taskId, props.action, props.command)
                .then((response) => {
                    return { request: props, response: '' };
                });
        } catch (err: any) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    }
);

export const plannedTaskAction = createAsyncThunk(
    'tasks/completeTask',
    async (
        props: {
            taskId: TaskId;
            action: PlannedTaskAction;
            command: UpdateProjectCommand;
        },
        { rejectWithValue }
    ) => {
        try {
            return Api.defaultApi()
                .plannedTaskAction(props.taskId, props.action, props.command)
                .then((response) => {
                    return { request: props, response: '' };
                });
        } catch (err: any) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    }
);

export const hideCompletedTasksAction = createAsyncThunk(
    'tasks/hideCompletedTasks',
    async (props: undefined, { rejectWithValue }) => {
        try {
            return Api.defaultApi().hideCompletedTasks();
        } catch (err: any) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    }
);

export const idleAllPlannedTasksAction = createAsyncThunk(
    'tasks/idleAllPlannedTasks',
    async (props: undefined, { rejectWithValue }) => {
        try {
            return Api.defaultApi().clearAllPlannedTasks();
        } catch (err: any) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    }
);

const workspaceSlice = createSlice({
    name: 'workspace',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // fetchWorkspace
            .addCase(fetchWorkspace.pending, (state) => {
                state.status = 'LOADING';
                state.error = null;
            })
            .addCase(fetchWorkspace.fulfilled, (state, action) => {
                state.status = 'SUCCEEDED';
                state.id = action.payload.workspaceId;
                state.projects = action.payload.projects.map((p) => {
                    const projectTasks: TaskStateObject[] = p.tasks.map((t) => {
                        return {
                            ...t,
                            state: !!t.completedAt
                                ? 'DONE'
                                : action.payload.plannedTasks.indexOf(t.id) > -1
                                ? 'PLANNED'
                                : 'IDLE',
                        };
                    });
                    return {
                        ...p,
                        id: p.id as ProjectId,
                        workspaceId: (p.shared?.workspaceId ||
                            action.payload.workspaceId) as WorkspaceId,
                        projectRef: {
                            projectId: p.id as ProjectId,
                            workspaceId: action.payload
                                .workspaceId as WorkspaceId,
                        },
                        tasks: projectTasks,
                        shared:
                            p.shared == null
                                ? null
                                : {
                                      workspaceId: p.shared
                                          .workspaceId as WorkspaceId,
                                      ownerId: p.shared.ownerId,
                                  },
                    };
                });
                state.tasks = action.payload.tasks.map((t) => {
                    return {
                        ...t,
                        state: !!t.completedAt
                            ? 'DONE'
                            : action.payload.plannedTasks.indexOf(t.id) > -1
                            ? 'PLANNED'
                            : 'IDLE',
                    };
                });
                state.archivedProjectsCount =
                    action.payload.archivedProjectsCount;
                state.plannedTasks = action.payload.plannedTasks;
            })
            .addCase(fetchWorkspace.rejected, (state, action) => {
                state.status = 'FAILED';
                state.error = action.error.message || 'An error occurred';
            })
            // addProject
            .addCase(addProject.pending, (state) => {
                state.error = null;
            })
            .addCase(addProject.fulfilled, (state, action) => {
                console.log('Adding', { state, action });
                state.projects.push({
                    id: action.payload.projectId as ProjectId,
                    workspaceId: action.payload.workspaceId as WorkspaceId,
                    projectRef: {
                        projectId: action.payload.projectId as ProjectId,
                        workspaceId: action.payload.workspaceId as WorkspaceId,
                    },
                    name: action.payload.name,
                    color: action.payload.color,
                    tasks: [],
                    shared: null,
                    createdAt: new Date().toISOString(),
                    updatedAt: new Date().toISOString(),
                });
            })
            .addCase(addProject.rejected, (state, action) => {
                state.error = action.error.message || 'An error occurred';
            })
            // renameProject
            .addCase(renameProject.pending, (state) => {
                state.error = null;
            })
            .addCase(renameProject.fulfilled, (state, action) => {
                state.projects
                    .filter((p) => p.id === action.payload.request.projectId)
                    .forEach(
                        (p) => (p.name = action.payload.request.command.name)
                    );
            })
            .addCase(renameProject.rejected, (state, action) => {
                state.error = action.error.message || 'An error occurred';
            })
            // reorderProject
            .addCase(reorderProject.pending, (state) => {
                state.error = null;
            })
            .addCase(reorderProject.fulfilled, (state, action) => {
                let projectsNewOrder = [...current(state.projects)];
                let item = projectsNewOrder[action.payload.itemIndex];
                projectsNewOrder.splice(action.payload.itemIndex, 1);

                state.projects =
                    action.payload.itemIndex < 0
                        ? projectsNewOrder
                        : [
                              ...projectsNewOrder.slice(
                                  0,
                                  action.payload.index
                              ),
                              item,
                              ...projectsNewOrder.slice(action.payload.index),
                          ];
            })
            .addCase(reorderProject.rejected, (state, action) => {
                state.error = action.error.message || 'An error occurred';
            })
            // fetchArchivedProjects
            .addCase(fetchArchivedProjects.pending, (state) => {
                state.archivedProjects.status = 'LOADING';
                state.archivedProjects.error = null;
            })
            .addCase(fetchArchivedProjects.fulfilled, (state, action) => {
                state.archivedProjects.status = 'SUCCEEDED';
                state.archivedProjects.projects = action.payload;
            })
            .addCase(fetchArchivedProjects.rejected, (state, action) => {
                state.archivedProjects.status = 'FAILED';
                state.archivedProjects.error =
                    action.error.message || 'An error occurred';
            })
            // archiveProject
            .addCase(archiveProject.pending, (state, action) => {
                state.error = null;
            })
            .addCase(archiveProject.fulfilled, (state, action) => {
                const archivedProject = state.projects.find(
                    (project: any) => project.id === action.payload
                );
                state.projects = state.projects.filter(
                    (project: any) => project.id !== action.payload
                );
                if (archivedProject) {
                    state.archivedProjects.projects.push({
                        id: archivedProject.id,
                        name: archivedProject.name,
                        color: archivedProject.color,
                    });
                }
                state.archivedProjectsCount = state.archivedProjectsCount + 1;
            })
            .addCase(archiveProject.rejected, (state, action) => {
                state.error = action.error.message || 'An error occurred';
            })
            // unarchiveProject
            .addCase(unarchiveProject.pending, (state) => {
                state.error = null;
            })
            .addCase(unarchiveProject.fulfilled, (state, action) => {
                state.archivedProjects.projects =
                    state.archivedProjects.projects.filter(
                        (project: any) => project.id !== action.payload.id
                    );
                state.projects.push(action.payload);
                state.archivedProjectsCount = state.archivedProjectsCount - 1;
            })
            .addCase(unarchiveProject.rejected, (state, action) => {
                state.error = action.error.message || 'An error occurred';
            })
            // deleteProject
            .addCase(deleteProject.pending, (state) => {
                state.error = null;
            })
            .addCase(deleteProject.fulfilled, (state, action) => {
                state.projects = state.projects.filter(
                    (project: any) => project.id !== action.payload
                );
            })
            .addCase(deleteProject.rejected, (state, action) => {
                state.error = action.error.message || 'An error occurred';
            })
            // addTask
            .addCase(addTask.pending, (state) => {
                state.error = null;
            })
            .addCase(addTask.fulfilled, (state, action) => {
                const _planned = action.payload.request.planned || false;
                const _state = _planned ? 'PLANNED' : 'IDLE';
                const newTask = {
                    id: action.payload.response.id,
                    name: action.payload.request.name,
                    planned: _planned,
                    done: action.payload.response.done,
                    createdAt: new Date().toISOString(),
                    completedAt: null,
                    state: _state as TaskState,
                };
                if (state.id === action.payload.response.projectId) {
                    state.tasks.push(newTask);
                } else {
                    state.projects
                        .filter(
                            (p) => p.id === action.payload.response.projectId
                        )
                        .forEach((p) => p.tasks.push(newTask));
                }
                if (
                    state.plannedTasks.indexOf(action.payload.response.id) ===
                        -1 &&
                    _planned
                ) {
                    state.plannedTasks.push(action.payload.response.id);
                }
            })
            .addCase(addTask.rejected, (state, action) => {
                state.error = action.error.message || 'An error occurred';
            })
            // deleteTask
            .addCase(deleteTask.pending, (state) => {
                state.error = null;
            })
            .addCase(deleteTask.fulfilled, (state, action) => {
                state.plannedTasks = state.plannedTasks.filter(
                    (tid) => tid !== action.payload.request.taskId
                );
                state.tasks = state.tasks.filter(
                    (t) => t.id !== action.payload.request.taskId
                );
                state.projects
                    .filter(
                        (p) => p.id === action.payload.request.command.projectId
                    )
                    .forEach(
                        (p) =>
                            (p.tasks = p.tasks.filter(
                                (t) => t.id !== action.payload.request.taskId
                            ))
                    );
            })
            .addCase(deleteTask.rejected, (state, action) => {
                state.error = action.error.message || 'An error occurred';
            })
            // taskAction
            .addCase(taskAction.pending, (state) => {
                state.error = null;
            })
            .addCase(taskAction.fulfilled, (state, action) => {
                [
                    ...state.tasks.filter(
                        (t) => t.id === action.payload.request.taskId
                    ),
                    ...state.projects
                        .filter(
                            (p) =>
                                p.id ===
                                action.payload.request.command.projectId
                        )
                        .flatMap((p) =>
                            p.tasks.filter(
                                (t) => t.id === action.payload.request.taskId
                            )
                        ),
                ].forEach((t) => {
                    if (action.payload.request.action === 'PLAN') {
                        t.state = 'PLANNED';
                        state.plannedTasks.push(t.id);
                    } else {
                        t.state = 'IDLE';
                        state.plannedTasks = state.plannedTasks.filter(
                            (tid) => tid !== t.id
                        );
                    }
                });
                state.tasks = [...state.tasks];
                state.projects = [...state.projects];
            })
            .addCase(taskAction.rejected, (state, action) => {
                state.error = action.error.message || 'An error occurred';
            })
            // plannedTaskAction
            .addCase(plannedTaskAction.pending, (state) => {
                state.error = null;
            })
            .addCase(plannedTaskAction.fulfilled, (state, action) => {
                [
                    ...state.tasks.filter(
                        (t) => t.id === action.payload.request.taskId
                    ),
                    ...state.projects
                        .filter(
                            (p) =>
                                p.id ===
                                action.payload.request.command.projectId
                        )
                        .flatMap((p) =>
                            p.tasks.filter(
                                (t) => t.id === action.payload.request.taskId
                            )
                        ),
                ].forEach((t) => {
                    if (action.payload.request.action === 'COMPLETE') {
                        t.state = 'DONE';
                        t.done = true;
                        t.completedAt = new Date().toISOString();
                    } else {
                        t.state = 'PLANNED';
                        t.done = false;
                        t.completedAt = null;
                    }
                });
                state.tasks = [...state.tasks];
                state.projects = [...state.projects];
            })
            .addCase(plannedTaskAction.rejected, (state, action) => {
                state.error = action.error.message || 'An error occurred';
            })
            // hideCompletedTasksAction
            .addCase(hideCompletedTasksAction.pending, (state) => {
                state.error = null;
            })
            .addCase(hideCompletedTasksAction.fulfilled, (state, action) => {
                state.tasks = state.tasks.filter((t) => t.completedAt === null);
                state.projects.forEach(
                    (p) =>
                        (p.tasks = p.tasks.filter(
                            (t) => t.completedAt === null
                        ))
                );
                state.projects = [...state.projects];
            })
            .addCase(hideCompletedTasksAction.rejected, (state, action) => {
                state.error = action.error.message || 'An error occurred';
            })
            // idleAllPlannedTasksAction
            .addCase(idleAllPlannedTasksAction.pending, (state) => {
                state.error = null;
            })
            .addCase(idleAllPlannedTasksAction.fulfilled, (state, action) => {
                const workspaceIdleTasks = state.tasks
                    .filter((t) => t.state === 'PLANNED')
                    .map((t) => {
                        t.state = 'IDLE';
                        t.done = false;
                        t.completedAt = null;

                        return t.id;
                    });
                const projectsIdleTasks = state.projects.flatMap((p) =>
                    p.tasks
                        .filter((t) => t.state === 'PLANNED')
                        .map((t) => {
                            t.state = 'IDLE';
                            t.done = false;
                            t.completedAt = null;

                            return t.id;
                        })
                );
                state.projects = [...state.projects];
                state.plannedTasks = state.plannedTasks.filter(
                    (id) =>
                        workspaceIdleTasks.indexOf(id) === -1 &&
                        projectsIdleTasks.indexOf(id) === -1
                );
            })
            .addCase(idleAllPlannedTasksAction.rejected, (state, action) => {
                state.error = action.error.message || 'An error occurred';
            });
    },
});

export const selectProject = (state: RootState, projectId: string) => {
    return state.workspace.projects.filter(
        (project: ProjectState) => project.id === projectId
    )[0];
};

export default workspaceSlice.reducer;
