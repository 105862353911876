import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from './index';
export type ThemeType = 'light' | 'dark';
export type ViewType = 'list' | 'grid';

export interface LayoutState {
    theme: ThemeType;
    view: ViewType;
    projectsReorder: boolean;
}

const initialState: LayoutState = {
    theme: 'light',
    view: 'list',
    projectsReorder: false,
};

const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        setTheme: (state, action) => {
            state.theme = action.payload;
        },
        changeTheme: (state) => {
            state.theme = state.theme === 'light' ? 'dark' : 'light';
        },
        setView: (state, action) => {
            state.view = action.payload;
        },
        changeView: (state) => {
            state.view = state.view === 'list' ? 'grid' : 'list';
        },
        setProjectsReorder: (state, action) => {
            state.projectsReorder = action.payload;
        },
        toggleprojectsReorder: (state) => {
            state.projectsReorder = !state.projectsReorder;
        },
    },
});

export const {
    setTheme,
    changeTheme,
    setView,
    changeView,
    setProjectsReorder,
    toggleprojectsReorder,
} = layoutSlice.actions;

export const selectLayout = (state: RootState) => state.layout;

export default layoutSlice.reducer;
