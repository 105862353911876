import React, { useState } from 'react';
import { ReactComponent as CheckboxCheckedIcon } from '../../../assets/icons/checkbox-checked.svg';
import { ReactComponent as CheckboxUncheckedIcon } from '../../../assets/icons/checkbox-unchecked.svg';
import { ReactComponent as CheckboxAnimationIcon } from '../../../assets/icons/checkbox-animation.svg';
import styles from './TaskCheckbox.module.scss';

const TaskCheckbox = (props: { checked: boolean; onClick: any }) => {
    const [pristine, setPristine] = useState<boolean>(true);
    const [checked, setChecked] = useState<boolean>(props.checked);

    const onClicked = () => {
        setPristine(false);
        let newValue = !checked;
        setChecked(newValue);
        props.onClick(newValue);
    };

    if (pristine) {
        return checked ? (
            <CheckboxCheckedIcon
                className={'task-planned__checkbox'}
                onClick={onClicked}
            ></CheckboxCheckedIcon>
        ) : (
            <CheckboxUncheckedIcon
                className={'task-planned__checkbox'}
                onClick={onClicked}
            ></CheckboxUncheckedIcon>
        );
    }
    return checked ? (
        <CheckboxAnimationIcon
            className={'task-planned__checkbox'}
            onClick={onClicked}
        ></CheckboxAnimationIcon>
    ) : (
        <CheckboxUncheckedIcon
            className={'task-planned__checkbox'}
            onClick={onClicked}
        ></CheckboxUncheckedIcon>
    );
};

export default TaskCheckbox;
