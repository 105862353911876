import z from 'zod';

export const shapes = {
    UpdateProjectCommand: z.object({
        workspaceId: z.string(),
        projectId: z.string(),
    }),
    AddProjectCommand: z.object({
        workspaceId: z.string(),
        name: z.string(),
        color: z.string().optional(),
    }),
    RenameProjectCommand: z.object({
        workspaceId: z.string(),
        name: z.string(),
    }),
    AddProjectResponse: z.object({
        workspaceId: z.string(),
        projectId: z.string(),
        name: z.string(),
        color: z.string().or(z.null()),
    }),
    ReadArchivedProjectsResponse: z.array(
        z.object({
            id: z.string(),
            name: z.string(),
            color: z.string(),
        })
    ),
};

export type UpdateProjectCommand = z.infer<typeof shapes.UpdateProjectCommand>;
export type RenameProjectCommand = z.infer<typeof shapes.RenameProjectCommand>;

export type AddProjectCommand = z.infer<typeof shapes.AddProjectCommand>;
export type AddProjectResponse = z.infer<typeof shapes.AddProjectResponse>;

export type ReadArchivedProjectsResponse = z.infer<
    typeof shapes.ReadArchivedProjectsResponse
>;
