import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../store';
import {
    fetchArchivedProjects,
    unarchiveProject,
    WorkspaceState,
} from '../../store/workspaceSlice';

export const ProjectsArchive = () => {
    const dispatch = useDispatch<AppDispatch>();
    const workspaceData: WorkspaceState = useSelector(
        (state: any) => state.workspace
    );
    const archivedProjects = useSelector(
        (state: any) => state.workspace.archivedProjects.projects
    );
    const archivedProjectsStatus = useSelector(
        (state: any) => state.workspace.archivedProjects.status
    );

    useEffect(() => {
        dispatch(fetchArchivedProjects(workspaceData.id));
    }, [workspaceData.id]);

    if (archivedProjectsStatus === 'LOADING') {
        return <>Loading</>;
    }

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {archivedProjects.map((p: any) => (
                    <div
                        key={p.id}
                        style={{
                            padding: '3px',
                            paddingLeft: '12px',
                            marginBottom: '12px',
                            display: 'flex',
                            flexDirection: 'row',
                            border: '1px',
                            borderStyle: 'solid',
                        }}
                    >
                        <div style={{ color: p.color, fontWeight: 'bold' }}>
                            {p.name}
                        </div>
                        <div
                            style={{ marginLeft: '16px', cursor: 'pointer' }}
                            onClick={() => dispatch(unarchiveProject(p.id))}
                        >
                            Bring back
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};
