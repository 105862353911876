import z from 'zod';

export const shapes = {
    AddTaskCommand: z.object({
        workspaceId: z.string(),
        projectId: z.string().optional(),
        name: z.string(),
        planned: z.boolean().optional(),
    }),
    TaskDto: z.object({
        workspaceId: z.string(),
        projectId: z.string(),
        id: z.string(),
        done: z.boolean(),
    }),
};

export type AddTaskCommand = z.infer<typeof shapes.AddTaskCommand>
export type TaskDto = z.infer<typeof shapes.TaskDto>

