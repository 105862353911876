import {useNavigate, useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import Button from './layout/atoms/Button';
import axios from 'axios';
import {Environment} from "./Environment";


export const InviteLanding = (props: any) => {
  const { inviteToken } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [joining, setJoining] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  // const [projectDetails, setProjectDetails] = useState<ProjectInviteDetails | null>(null);
  const [projectDetails, setProjectDetails] =
    useState<ProjectInviteDetails | null>(null);

  useEffect(() => {
    if (inviteToken) {
      fetchProjectInviteDetails(inviteToken);
    }
  }, [inviteToken]);

  if (!validateInviteToken(inviteToken as string)) {
    return (
      <>
        <h1>Invalid invite token</h1>
      </>
    );
  }

  const joinProject = () => {
    setJoining(true);
    axios
      .post<void>(
        `${Environment.backendUrl}/api/v1/invites/${inviteToken}/join`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('hustleToken')}`,
          },
        }
      )
      .catch((error) => setError(error))
      .then((response: any) => {
        if (response?.status === 200) {
          navigate('/');
        } else {
          setError('Could not join the project.');
        }
      })
      .finally(() => setJoining(false));
  };

  const fetchProjectInviteDetails = async (inviteToken: string) => {
    setLoading(true);
    axios
      .get<void>(`${Environment.backendUrl}/api/v1/invites/${inviteToken}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('hustleToken')}`,
        },
      })
      .catch((error) => setError(error))
      .then((response: any) => {
        if (response) {
          if (response?.data) {
            setProjectDetails(response?.data as ProjectInviteDetails);
          } else {
            throw Error(`Unparsable response ${JSON.stringify(response)}`);
          }
        }
      })
      .catch((error) => setError('Cannot read invite ' + JSON.stringify(error)))
      .finally(() => setLoading(false));
  };

  if (error) {
    return (
      <>
        <h2>Error</h2>
        <small>{error}</small>
      </>
    );
  }
  if (projectDetails === null) {
    return (
      <>
        <h2>Loading invite...</h2>
        <small>Checking token {inviteToken}</small>
      </>
    );
  }

  if (joining) {
    return (
      <>
        <h2>
          Joining{' '}
          <b style={{ color: projectDetails.color }}>{projectDetails.name}</b>{' '}
          🚀
        </h2>
      </>
    );
  }
  let loggedIn = !!localStorage.getItem('hustleToken');
  return (
    <>
      <h1>
        Join{' '}
        <b style={{ color: projectDetails.color }}>{projectDetails.name}</b>{' '}
        project!
      </h1>
      {loggedIn && <Button onClick={joinProject}>Join the project</Button>}
      {!loggedIn && <>Log in to join</>}
    </>
  );
};

const validateInviteToken = (token: string): boolean => {
  return !!token && token.length > 10;
};

type ProjectInviteDetails = {
  name: string;
  color: string;
};
