import React from 'react';
import styles from './ListHeader.module.scss';

const ListHeader = (props: { text?: string; children?: React.ReactNode }) => {
    return (
        <div className={`${styles.wrap}`}>
            <h2>{props.text ? props.text : ' '}</h2>
            {props.children}
        </div>
    );
};

export default ListHeader;
