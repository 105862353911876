import React, {
    FC,
    useState,
    useEffect,
    useCallback,
    forwardRef,
    HTMLAttributes,
    CSSProperties,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../store';
import {
    archiveProject,
    deleteProject,
    reorderProject,
    WorkspaceState,
} from '../../../store/workspaceSlice';
import { LayoutState, setProjectsReorder } from '../../../store/layoutSlice';
import Container from '../../atoms/Container/Container';
import { ReactComponent as MoveIcon } from '../../../assets/icons/move.svg';
import Dropdown from '../../atoms/Dropdown';
import Button from '../../atoms/Button';

import {
    DndContext,
    closestCenter,
    MouseSensor,
    TouchSensor,
    DragOverlay,
    useSensor,
    useSensors,
    DragStartEvent,
    DragEndEvent,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    rectSortingStrategy,
} from '@dnd-kit/sortable';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Project from '../../molecules/Project/Project';
import ListHeader from '../../atoms/ListHeader/ListHeader';
import ViewToggler from '../../atoms/ViewToggler';
import styles from './ProjectsList.module.scss';

const SortableItem: FC<ItemProps> = (props) => {
    const {
        isDragging,
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: props.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition: transition || undefined,
    };

    return (
        <Item
            ref={setNodeRef}
            style={style}
            withOpacity={isDragging}
            {...props}
            {...attributes}
            {...listeners}
        />
    );
};

export type ItemProps = HTMLAttributes<HTMLDivElement> & {
    id: string;
    withOpacity?: boolean;
    isDragging?: boolean;
};

const Item = forwardRef<HTMLDivElement, ItemProps>(
    ({ id, withOpacity, isDragging, style, ...props }, ref) => {
        const dispatch = useDispatch<AppDispatch>();

        const handleArchiveProject = (projectId: string) => {
            dispatch(archiveProject(projectId));
        };

        const handleDeleteProject = (projectId: string) => {
            dispatch(deleteProject(projectId));
        };

        const inlineStyles: CSSProperties = {
            opacity: withOpacity ? '0.5' : '1',
            transformOrigin: '50% 50%',
            cursor: isDragging ? 'grabbing' : 'grab',
            ...style,
        };

        return (
            <div ref={ref} style={inlineStyles} {...props}>
                <Project
                    key={id}
                    id={id}
                    folded={true}
                    onProjectDelete={() => handleDeleteProject(id)}
                    onProjectArchive={() => handleArchiveProject(id)}
                />
            </div>
        );
    }
);

const ProjectsList = () => {
    const dispatch = useDispatch<AppDispatch>();
    const layoutState: LayoutState = useSelector((state: any) => state.layout);
    const workspaceData: WorkspaceState = useSelector(
        (state: any) => state.workspace
    );
    const activeProjects = workspaceData.projects;

    const handleReorderProject = async (
        projectId: string,
        itemIndex: number,
        index: number
    ) => {
        return dispatch(
            reorderProject({
                workspaceId: workspaceData.id,
                projectId,
                itemIndex,
                index,
            })
        );
    };

    const [items, setItems] = useState<string[]>(
        activeProjects.map((project) => project.id)
    );
    const [activeId, setActiveId] = useState<string | null>(null);
    const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

    const handleDragStart = useCallback((event: DragStartEvent) => {
        setTimeout(() => setActiveId(event.active.id.toString()), 2000);
    }, []);

    const handleDragEnd = useCallback((event: DragEndEvent) => {
        const { active, over } = event;

        if (active.id !== over?.id) {
            setItems((items) => {
                const oldIndex = items.indexOf(active.id.toString());
                const newIndex = items.indexOf(over!.id.toString());
                handleReorderProject(active.id.toString(), oldIndex, newIndex);
                return arrayMove(items, oldIndex, newIndex);
            });
        }

        setActiveId(null);
    }, []);

    const handleDragCancel = useCallback(() => {
        setActiveId(null);
    }, []);

    const handleArchiveProject = (projectId: string) => {
        dispatch(archiveProject(projectId));
    };

    const handleDeleteProject = (projectId: string) => {
        dispatch(deleteProject(projectId));
    };

    return (
        <>
            <Container narrow={layoutState.view === 'list'} margin>
                <ListHeader text="Projects">
                    {layoutState.projectsReorder ? (
                        <Button
                            size="m"
                            onClick={() => dispatch(setProjectsReorder(false))}
                        >
                            <MoveIcon /> <p>Finish</p>
                        </Button>
                    ) : (
                        <Dropdown position="bottomRight" horizontal={true}>
                            <Button
                                size="m"
                                onClick={() =>
                                    dispatch(setProjectsReorder(true))
                                }
                            >
                                <MoveIcon /> <p>Reorder</p>
                            </Button>
                            <ViewToggler />
                        </Dropdown>
                    )}
                </ListHeader>
                {layoutState.projectsReorder ? (
                    <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragStart={handleDragStart}
                        onDragEnd={handleDragEnd}
                        onDragCancel={handleDragCancel}
                    >
                        <SortableContext
                            items={items}
                            strategy={rectSortingStrategy}
                        >
                            <div
                                className={`${styles.wrap} ${
                                    layoutState.view === 'list'
                                        ? styles.list
                                        : styles.grid
                                }`}
                            >
                                {items.map((projectId) => (
                                    <SortableItem
                                        key={projectId}
                                        id={projectId}
                                    />
                                ))}
                            </div>
                        </SortableContext>
                        <DragOverlay
                            adjustScale
                            style={{ transformOrigin: '0 0 ' }}
                        >
                            {activeId ? (
                                <Item id={activeId} isDragging />
                            ) : null}
                        </DragOverlay>
                    </DndContext>
                ) : (
                    <div
                        className={`${styles.wrap} ${
                            layoutState.view === 'list'
                                ? styles.list
                                : styles.grid
                        }`}
                    >
                        {activeProjects.map((project) => (
                            <div>
                                <Project
                                    key={project.id}
                                    id={project.id}
                                    onProjectDelete={() =>
                                        handleDeleteProject(project.id)
                                    }
                                    onProjectArchive={() =>
                                        handleArchiveProject(project.id)
                                    }
                                />
                            </div>
                        ))}
                    </div>
                )}
            </Container>
        </>
    );
};

export default ProjectsList;
