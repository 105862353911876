import React, { useEffect, useState } from 'react';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Workspace from './workspace/Workspace';
import Topbar from './layout/atoms/Topbar/Topbar';
import Logo from './layout/atoms/Logo';
import ThemeToggler from './layout/atoms/ThemeToggler';
import { LoginPage } from './login/LoginPage';
import { MagicLink } from './login/MagicLink';
import Button from './layout/atoms/Button';
import Dropdown from './layout/atoms/Dropdown';
import { InviteLanding } from './InviteLanding';

import { ReactComponent as PowerIcon } from './assets/icons/power.svg';
import { Environment } from './Environment';
import { LoginCode } from './login/LoginCode';
import { useDispatch, useSelector } from 'react-redux';
import { logout, readSession, UserState } from './store/userSlice';
import { setTheme, setView } from './store/layoutSlice';
import { AppDispatch } from './store';
import InitLoader from './layout/molecules/InitLoader';

const App = () => {
    const dispatch = useDispatch<AppDispatch>();
    const [loading, setLoading] = useState(true);
    const [appError, setAppError] = useState<any>(null);

    const userState: UserState = useSelector((state: any) => state.user);

    const checkUserLoggedIn = async () => {
        if (userState.data) {
            return;
        }

        const storedToken = localStorage.getItem('hustleToken');

        let token;

        if (storedToken) {
            token = storedToken;
        } else {
            const params = new URLSearchParams(window.location.search);
            const token = params.get('token');
            if (token) {
                localStorage.setItem('hustleToken', token);
                params.delete('token');

                const newSearchParams = params.toString();
                const newUrl =
                    window.location.pathname +
                    (newSearchParams ? `?${newSearchParams}` : '');

                window.location.replace(newUrl);
            }
        }

        dispatch(readSession()).finally(() => setLoading(false));
    };

    useEffect(() => {
        checkUserLoggedIn();
    }, []);

    const onUserLoggedIn = async () => {
        await checkUserLoggedIn();
    };

    const getUserInfo = async (session: string) => {
        try {
            const response = await fetch(`${Environment.backendUrl}/session`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${session}`,
                },
            });
            if (response.ok) {
                return response.json();
            } else {
                setAppError('Cannot read user data: ' + response.status);
            }
            console.log({ response });
        } catch (error) {
            console.error(error);
        }
    };

    const signOut = async () => {
        localStorage.removeItem('hustleToken');
        dispatch(logout());
    };

    useEffect(() => {
        const darkThemeEnabled = localStorage.getItem('hustleTheme') === 'dark';
        if (darkThemeEnabled) {
            document.body.classList.add('dark-theme');
            dispatch(setTheme('dark'));
        } else {
            document.body.classList.remove('dark-theme');
            dispatch(setTheme('light'));
        }

        localStorage.getItem('hustleView') === 'grid'
            ? dispatch(setView('grid'))
            : dispatch(setView('list'));
    }, []);

    if (appError) {
        return (
            <>
                App error: {appError}
                <Button onClick={signOut}>Logout</Button>
            </>
        );
    }

    if (loading) {
        return <InitLoader withLogo />;
    }

    return (
        <>
            <Topbar active={userState.data !== null}>
                <Logo />
                {userState.data && (
                    <Dropdown
                        position="bottomRight"
                        trigger={
                            <Button size="m" theme="plain">
                                <p>{userState.data.userEmail}</p>
                            </Button>
                        }
                    >
                        <ThemeToggler />
                        <Button size="m" theme="blank" onClick={signOut}>
                            <PowerIcon /> <p>Logout</p>
                        </Button>
                    </Dropdown>
                )}
            </Topbar>
            {userState.data === null && (
                <Router>
                    <Routes>
                        <Route
                            path="/magic-link/sent"
                            element={<MagicLink mode={'EMAIL_SENT'} />}
                        />
                        <Route
                            path="/magic-link"
                            element={<MagicLink mode={'GET_EMAIL'} />}
                        />
                        <Route
                            path="/login"
                            element={<LoginCode mode={'GET_EMAIL'} />}
                        />
                        <Route
                            path="/login"
                            element={
                                <LoginPage
                                    onUserLoggedIn={onUserLoggedIn}
                                ></LoginPage>
                            }
                        />
                        <Route
                            path="/magic-link"
                            element={<MagicLink mode={'GET_EMAIL'} />}
                        />
                        <Route
                            path="/*"
                            element={
                                <LoginPage
                                    onUserLoggedIn={onUserLoggedIn}
                                ></LoginPage>
                            }
                        />
                    </Routes>
                </Router>
            )}
            {userState.data !== null && (
                <Router>
                    <Routes>
                        <Route
                            path="/dashboard"
                            element={
                                <Workspace
                                    workspaceId={userState.data.workspaceId}
                                />
                            }
                        />
                        <Route
                            path="/invite/:inviteToken"
                            element={<InviteLanding />}
                        />
                        <Route
                            path="*"
                            element={
                                <Workspace
                                    workspaceId={userState.data.workspaceId}
                                />
                            }
                        />
                    </Routes>
                </Router>
            )}
        </>
    );
};

export default App;
