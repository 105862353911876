import React from 'react';
import styles from './Container.module.scss';

const Container = (props: {
    narrow?: boolean;
    margin?: boolean;
    children: React.ReactNode;
}) => {
    return (
        <div
            className={`${styles.wrap} ${props.narrow && styles.narrow} ${
                props.margin && styles.margin
            }`}
        >
            {props.children}
        </div>
    );
};

export default Container;
