import React, { useState } from 'react';
import TextEditable from '../../layout/atoms/TextEditable';
import { useDispatch } from 'react-redux';
import { addProject } from '../../store/workspaceSlice';
import { AppDispatch } from '../../store';

export const AddProject = (props: {
    workspaceId: string;
    availableColorProvider: () => string;
}) => {
    const [newProjectName, setNewProjectName] = useState('');

    const dispatch: AppDispatch = useDispatch();

    const [isEditing, setIsEditing] = useState(false);
    const [isAdding, setIsAdding] = useState(false);

    const doAddProject = (name: string) => {
        setIsAdding(true);
        return dispatch(
            addProject({
                workspaceId: props.workspaceId,
                name,
                color: props.availableColorProvider(),
            })
        )
            .finally(() => setIsAdding(false))
            .then((action) => {
                if (addProject.fulfilled.match(action)) {
                    setNewProjectName('');
                }
            });
    };

    const onAddConfirmedButtonClicked = () => {
        renameConfirmed();
    };

    const renameConfirmed = () => {
        if (newProjectName && newProjectName.trim()) {
            doAddProject(newProjectName.trim());
        }
    };

    const onAddNewProject = () => {
        setIsEditing(true);
    };

    const handleBlur = () => {
        setIsEditing(false);
    };

    const handleChange = (event: any) => {
        setNewProjectName(event.target.value);
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        event.stopPropagation();
        if (event.key === 'Enter') {
            renameConfirmed();
        } else if (event.key === 'Escape') {
        }
    };

    if (isAdding) {
        return <>Adding project...</>;
    }

    if (isEditing) {
        return (
            <TextEditable
                value={newProjectName}
                handleChange={handleChange}
                handleKeyDown={handleKeyDown}
                handleBlur={handleBlur}
                maxLength={100}
                placeholder="Enter text (max 100 characters)"
                onRenameConfirmedButtonClicked={onAddConfirmedButtonClicked}
            />
        );
    } else {
        return (
            <div className={'button'}>
                <span onClick={onAddNewProject}>+ new project</span>
            </div>
        );
    }
};
