import { useEffect, useState } from 'react';
import axios from 'axios';
import Button from '../../atoms/Button';
import { Environment } from '../../../Environment';

import Modal from '../../atoms/Modal';

export const Invite = (props: {
    opened: boolean;
    workspaceId: string;
    projectId: string;
    handleModalClose: () => void;
}) => {
    // check if can share link PWA-style
    const shareMode: 'SHARE' | 'COPY' =
        !navigator.share || !navigator.canShare ? 'COPY' : 'SHARE';

    const [loadingLink, setLoadingLink] = useState<boolean>(true);
    const [error, setError] = useState<any>(undefined);
    const [link, setLink] = useState<string>('');
    const [modalOpened, setModalOpened] = useState<boolean>(props.opened);
    const [clipBoardMessageVisible, setClipBoardMessageVisible] =
        useState<boolean>(false);

    const sanitizeLink = (link: string): string => {
        if (!link.startsWith('http') && !link.includes('://')) {
            const currentProtocol = window.location.protocol;
            const currentHostname = window.location.hostname;
            const currentPort = window.location.port;
            const portSuffix =
                currentPort && currentPort !== '80' && currentPort !== '443'
                    ? `:${currentPort}`
                    : '';
            const cleanedUrl = link.replace(/^\/*/, '');
            return `${currentProtocol}//${currentHostname}${portSuffix}/${cleanedUrl}`;
        }
        return link;
    };

    const fetchProjectInviteLink = async (
        workspaceId: string,
        projectId: string
    ) => {
        axios
            .get<void>(
                `${Environment.backendUrl}/api/v1/projects/${props.projectId}/invite-link`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            'hustleToken'
                        )}`,
                    },
                }
            )
            .catch((error) => setError(error))
            .then((response: any) => {
                if (response) {
                    if (response?.data?.inviteLink) {
                        setLink(sanitizeLink(response.data.inviteLink));
                    } else {
                        throw Error(
                            `Unparsable response ${JSON.stringify(
                                response
                            )}, expecting inviteLink`
                        );
                    }
                }
            })
            .finally(() => setLoadingLink(false));
    };

    useEffect(() => {
        if (props && props.workspaceId && props.projectId) {
            fetchProjectInviteLink(props.workspaceId, props.projectId);
        }
    }, []);

    const copyToClipboard = () => {
        const dummyInput = document.createElement('input');
        document.body.appendChild(dummyInput);
        dummyInput.setAttribute('value', link);
        dummyInput.select();
        document.execCommand('copy');
        document.body.removeChild(dummyInput);

        setClipBoardMessageVisible(true);
    };

    const shareLink = () => {
        navigator
            .share({
                title: 'Tytuł linku',
                text: 'Opis linku',
                url: link,
            })
            .then(() => console.log('Link został udostępniony'))
            .catch((error) =>
                console.error('Błąd podczas udostępniania linku', error)
            );
    };

    if (error) {
        return (
            <div>Error fetching link, try again later. {error?.message}</div>
        );
    }
    return (
        <Modal open={props.opened} onClose={props.handleModalClose}>
            <header>
                <h2>Invite others</h2>
            </header>
            <main>
                <p>Send link to users:</p>
                <code>{link}</code>
            </main>
            <footer>
                {shareMode === 'SHARE' && (
                    <Button theme="plain" onClick={shareLink}>
                        <p>Share link</p>
                    </Button>
                )}

                {shareMode === 'COPY' && (
                    <Button theme="plain" onClick={copyToClipboard}>
                        <p>Copy to Clipboard</p>
                    </Button>
                )}
                <div>
                    {clipBoardMessageVisible && <p>Coppied to clipboard!</p>}
                </div>
            </footer>
        </Modal>
    );
};
