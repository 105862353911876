import React, { useState } from 'react';
import TextEditable from '../../layout/atoms/TextEditable';
import { MultiAddTaskPopup } from './MultiAddTaskPopup';

export const AddTask = (props: {
    buttonText?: string;
    color?: string | null;
    placeholder: string;
    onTasksAdded: (tasks: string[]) => Promise<any>;
}) => {
    const [userInput, setUserInput] = useState<string>('');
    const [isAddingTask, setIsAddingTask] = useState<boolean>(false);
    const [multiTasks, setMultiTasks] = useState<string[]>([]);

    const startAddingTask = () => {
        setIsAddingTask(true);
    };

    const handleChange = (event: any) => {
        if (event.target.value.endsWith('\n')) {
            return;
        }
        setUserInput(event.target.value);
    };

    const handleBlur = () => {
        if (multiTasks.length === 0) setIsAddingTask(false);
    };

    const onAddConfirmedButtonClicked = () => {
        return addingConfirmed();
    };

    const addingConfirmed = () => {
        if (!(userInput && userInput.trim())) {
            return;
        }

        if (multiTasks.length === 0) {
            const possibleTasks = userInput
                .split('\n')
                .map((t) => t.trim())
                .filter((t) => t.length > 0);

            if (possibleTasks.length > 1) {
                setMultiTasks(possibleTasks);
                return;
            }
        }
        return props.onTasksAdded([userInput]).then(() => {
            setUserInput('');
        });
    };

    const addMultiTasks = () => {
        return props.onTasksAdded(multiTasks).then(() => {
            setMultiTasks([]);
            setUserInput('');
            setIsAddingTask(false);
        });
    };

    const addConcatenatedTasks = () => {
        return props.onTasksAdded([userInput.trim()]).then(() => {
            setMultiTasks([]);
            setUserInput('');
        });
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        event.stopPropagation();
        if (event.key === 'Enter') {
            return addingConfirmed();
        } else if (event.key === 'Escape') {
            setIsAddingTask(false);
        }
    };

    return (
        <>
            {!isAddingTask && (
                <div
                    className={'button'}
                    style={{ color: props.color || '', alignContent: 'start' }}
                    onClick={startAddingTask}
                >
                    {props.buttonText || '+ NEW TASK'}
                </div>
            )}
            {isAddingTask && (
                <>
                    <TextEditable
                        value={userInput}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        handleKeyDown={handleKeyDown}
                        maxLength={2048}
                        placeholder={props.placeholder}
                        onRenameConfirmedButtonClicked={
                            onAddConfirmedButtonClicked
                        }
                    />
                    {multiTasks.length > 1 && (
                        <MultiAddTaskPopup
                            tasksCount={multiTasks.length}
                            addMultiTasks={addMultiTasks}
                            addConcatenatedTasks={addConcatenatedTasks}
                        />
                    )}
                </>
            )}
        </>
    );
};
