import Linkify from 'react-linkify';

const stopPropagation = (event: any) => event.stopPropagation();

export const TaskName = (props: { name: string }) => (
    <Linkify
        componentDecorator={(decoratedHref, decoratedText, key) => (
            <a target="blank" href={decoratedHref} key={key} onClick={stopPropagation}>
                {decoratedText}
            </a>
        )}
    >
        {props.name}
    </Linkify>
);
