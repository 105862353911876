import styles from './button.module.scss';

type ButtonProps = {
    size?: 's' | 'm' | 'l';
    theme?: 'blank' | 'plain' | 'primary';
    color?: string;
    disabled?: boolean;
    onClick?: any;
    children: React.ReactNode | React.ReactNode[];
};

const Button: React.FC<ButtonProps> = ({
    size,
    theme,
    color,
    disabled,
    onClick,
    children,
}: ButtonProps): JSX.Element => {
    const handleOnClick = () => {
        onClick && onClick();
    };

    return (
        <button
            className={`${styles.wrap} ${styles[size ? size : 'l']} ${
                styles[theme ? theme : 'blank']
            }`}
            disabled={disabled}
            onClick={handleOnClick}
        >
            {children}
        </button>
    );
};

export default Button;
