import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { ModifiedDroppable } from '../../layout/atoms/ModifiedDroppable';
import PlannedTask from './PlannedTask';
import { ProjectId, ProjectRef, TaskId, WorkspaceId } from '../../core/model';
import { Environment } from '../../Environment';
import {
    addTask,
    hideCompletedTasksAction,
    idleAllPlannedTasksAction,
    TaskStateObject,
    WorkspaceState,
} from '../../store/workspaceSlice';
import Button from '../../layout/atoms/Button';
import { AppDispatch } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from '../../layout/atoms/Dropdown';
import Container from '../../layout/atoms/Container/Container';
import { AddTask } from '../plan/AddTask';
import { ReactComponent as MoveBackIcon } from '../../assets/icons/move-back.svg';

export type PlannedTask = {
    task: TaskStateObject;
    color?: string | null;
    projectName?: string;
    projectRef: ProjectRef;
};

const Execute = (props: { workspaceId: WorkspaceId }) => {
    const dispatch = useDispatch<AppDispatch>();
    const workspaceData: WorkspaceState = useSelector(
        (state: any) => state.workspace
    );

    const [isAddingTask, setIsAddingTask] = useState<boolean>(false);

    const activeTasks: PlannedTask[] = [
        ...workspaceData.tasks
            .filter(
                (t) => t.done || workspaceData.plannedTasks.indexOf(t.id) >= 0
            )
            .map((task) => {
                return {
                    task,
                    projectRef: {
                        workspaceId: workspaceData.id as WorkspaceId,
                        projectId: workspaceData.id as ProjectId,
                    },
                };
            }),
        ...workspaceData.projects.flatMap((p) => {
            return p.tasks
                .filter(
                    (t) =>
                        t.done || workspaceData.plannedTasks.indexOf(t.id) >= 0
                )
                .map((task) => {
                    return {
                        task,
                        color: p.color,
                        projectName: p.name,
                        projectRef: {
                            workspaceId: p.workspaceId as WorkspaceId,
                            projectId: p.id,
                        },
                    };
                });
        }),
    ].sort(
        (t1, t2) =>
            workspaceData.plannedTasks.indexOf(t1.task.id) -
            workspaceData.plannedTasks.indexOf(t2.task.id)
    );

    const partitionedTasks = activeTasks.reduce(
        (acc, plannedTask) => {
            const group: PlannedTask[] =
                acc[plannedTask.task.completedAt === null ? 0 : 1];
            group.push(plannedTask);
            return acc;
        },
        [[], []]
    );
    const _plannedTasks: PlannedTask[] = partitionedTasks[0];
    const completedTasks: PlannedTask[] = partitionedTasks[1];
    const displaySeparator =
        _plannedTasks.length > 0 && completedTasks.length > 0;
    const [plannedTasks, setPlannedTasks] = useState<PlannedTask[]>([]);

    useEffect(() => {
        workspaceData.status === 'SUCCEEDED' && setPlannedTasks(_plannedTasks);
    }, [workspaceData]);

    const hideCompletedTasks = () => {
        dispatch(hideCompletedTasksAction());
    };

    const idleAllPlannedTasks = () => {
        dispatch(idleAllPlannedTasksAction());
    };

    const putToFront = (projectRef: ProjectRef, taskId: string) => {
        return axios.put(
            `${Environment.backendUrl}/api/v1/workspace/tasks/${taskId}/put-to-front`,
            {
                workspaceId: props.workspaceId,
                projectId: projectRef.projectId,
            },
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        'hustleToken'
                    )}`,
                },
            }
        );
    };

    const reorderAfterTask = (
        projectRef: ProjectRef,
        taskId: string,
        afterTaskId: TaskId
    ) => {
        return axios.put(
            `${Environment.backendUrl}/api/v1/workspace/tasks/${taskId}/reorder`,
            {
                workspaceId: props.workspaceId,
                projectId: projectRef.projectId,
                afterTaskId,
            },
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        'hustleToken'
                    )}`,
                },
            }
        );
    };

    const moveTask = (taskId: string, itemIndex: number, newIndex: number) => {
        const reorderFrontend = () => {
            if (itemIndex < 0) {
                return;
            }
            const [item] = plannedTasks.splice(itemIndex, 1);
            plannedTasks.splice(newIndex, 0, item);
            setPlannedTasks([...plannedTasks]);
        };

        if (newIndex === 0) {
            putToFront(plannedTasks[itemIndex].projectRef, taskId).then(
                reorderFrontend
            );
            return;
        }

        const afterTaskId =
            plannedTasks[itemIndex > newIndex ? newIndex - 1 : newIndex].task
                .id;
        reorderAfterTask(
            plannedTasks[itemIndex].projectRef,
            taskId,
            afterTaskId as TaskId
        ).then(reorderFrontend);
    };

    const dragEnded = (params: any) => {
        const { draggableId, source, destination } = params;
        moveTask(draggableId, source.index, destination.index);
    };

    const onQuickTasksAdded = async (tasks: string[]) => {
        for (let i = 0; i < tasks.length; i++) {
            const t = tasks[i];
            await dispatch(
                addTask({
                    workspaceId: props.workspaceId,
                    projectId: props.workspaceId,
                    name: t,
                    planned: true,
                })
            );
        }
    };

    return (
        <>
            <Container narrow>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: '1em',
                    }}
                >
                    <div style={{ flex: 1 }}>
                        <AddTask
                            buttonText={'Quick add'}
                            placeholder={'Quick add a task to your workspace'}
                            onTasksAdded={onQuickTasksAdded}
                        />
                    </div>
                    {plannedTasks.length > 0 && (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Dropdown position="bottomRight" horizontal={true}>
                                <Button onClick={idleAllPlannedTasks}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            height: '100vh',
                                        }}
                                    >
                                        <MoveBackIcon
                                            style={{ display: 'inline' }}
                                        />
                                        <span style={{ marginLeft: '6px' }}>
                                            Move&nbsp;all&nbsp;tasks&nbsp;to&nbsp;planning
                                        </span>
                                    </div>
                                </Button>
                            </Dropdown>
                        </div>
                    )}
                </div>
            </Container>
            <DragDropContext onDragEnd={dragEnded}>
                <ModifiedDroppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {plannedTasks &&
                                plannedTasks.map((task, index) => (
                                    <Draggable
                                        key={task.task.id}
                                        index={index}
                                        draggableId={task.task.id}
                                    >
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...task.projectRef}
                                                {...provided.draggableProps}
                                                style={
                                                    provided.draggableProps
                                                        .style
                                                }
                                            >
                                                <PlannedTask
                                                    key={task.task.id}
                                                    workspaceId={
                                                        props.workspaceId
                                                    }
                                                    projectRef={task.projectRef}
                                                    taskId={
                                                        task.task.id as TaskId
                                                    }
                                                    color={task.color || ''}
                                                    projectName={
                                                        task.projectName
                                                    }
                                                    dragHandleProps={
                                                        provided.dragHandleProps
                                                    }
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                        </div>
                    )}
                </ModifiedDroppable>
            </DragDropContext>
            <Container narrow>
                <div style={{ paddingTop: '3em' }}>
                    <>
                        {displaySeparator ? (
                            <div>
                                <small>Completed tasks</small>
                            </div>
                        ) : null}
                        {completedTasks.length > 0 ? (
                            <div>
                                <Button onClick={hideCompletedTasks}>
                                    Hide completed tasks
                                </Button>
                            </div>
                        ) : null}
                    </>
                    {completedTasks
                        .sort((t1, t2) =>
                            t2.task.state.localeCompare(t1.task.state)
                        )
                        .map((task, index) => (
                            <PlannedTask
                                workspaceId={props.workspaceId}
                                projectRef={task.projectRef}
                                taskId={task.task.id as TaskId}
                                color={task.color || ''}
                                projectName={task.projectName}
                                key={task.task.id}
                            />
                        ))}
                </div>
            </Container>
        </>
    );
};

export default Execute;
