import styles from './contentBox.module.scss';

type ContentBoxProps = {
  padding?: 'none' | 's' | 'm' | 'l';
  children: React.ReactNode | React.ReactNode[];
};

const ContentBox: React.FC<ContentBoxProps> = ({
  padding,
  children,
}: ContentBoxProps): JSX.Element => {
  return (
    <div
      className={`${styles.wrapper} ${
        styles[`pad-${padding ? padding : 'l'}`]
      }`}
    >
      {children}
    </div>
  );
};

export default ContentBox;
