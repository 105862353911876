import Button from '../../layout/atoms/Button';

export const MultiAddTaskPopup = (props: {
    tasksCount: number;
    addMultiTasks: () => void;
    addConcatenatedTasks: () => void;
}) => {
    return (
        <div
            className={'multi-add-task-popup'}
            style={{
                position: 'absolute',
                top: '100%',
                left: '50%',
                display: 'flex',
                zIndex: 1000,
                flexDirection: 'column',
                border: '1px solid #ccc',
                borderRadius: 'var(--radius)',
                transform: 'translate3d(-50%,0,0)',
            }}
        >
            <div
                style={{
                    margin: '8px',
                    paddingBottom: '8px',
                    fontWeight: 'bold',
                    borderBottom: '1px solid #ccc',
                }}
            >
                Would you like to create a task for every new line (
                {props.tasksCount}). <br />
                You can also add one task with a long name.
            </div>
            <Button onClick={props.addMultiTasks}>
                Add {props.tasksCount} tasks
            </Button>
            <Button onClick={props.addConcatenatedTasks}>Just one task</Button>
        </div>
    );
};
