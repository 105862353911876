import React, { useState, useEffect, useRef } from 'react';
import 'wicg-inert';

import Portal from '../Portal';

import styles from './modal.module.scss';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';

const Modal = (props: {
  open: boolean;
  onClose: () => void;
  locked?: boolean;
  children: React.ReactNode;
}) => {
  const [active, setActive] = useState(false);
  const { open, onClose, locked, children } = props;
  const backdrop = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const { current } = backdrop;
    const transitionEnd = () => setActive(open);
    const keyHandler = (e: any) =>
      !locked && [27].indexOf(e.which) >= 0 && onClose();
    const clickHandler = (e: any) =>
      !locked && e.target === current && onClose();

    if (current) {
      current.addEventListener('transitionend', transitionEnd);
      current.addEventListener('click', clickHandler);
      window.addEventListener('keyup', keyHandler);
    }

    if (open) {
      window.setTimeout(() => {
        // document.activeElement?.blur();
        setActive(open);
        document.querySelector('#root')?.setAttribute('inert', 'true');
        document.body.style.overflow = 'hidden';
      }, 10);
    }

    return () => {
      if (current) {
        current.removeEventListener('transitionend', transitionEnd);
        current.removeEventListener('click', clickHandler);
      }

      document.querySelector('#root')?.removeAttribute('inert');
      document.body.style.overflow = 'unset';
      window.removeEventListener('keyup', keyHandler);
    };
  }, [open, locked, onClose]);

  return (
    <>
      {(open || active) && (
        <Portal className="modal-portal">
          <div
            ref={backdrop}
            className={`${styles.backdrop} ${
              active && open && styles.backdropActive
            }`}
          >
            <div
              className={`${styles.content} ${
                active && open && styles.contentActive
              }`}
            >
              {props.children}

              <div className={`${styles.closeButton}`}>
                <CloseIcon onClick={onClose} />
              </div>
            </div>
          </div>
        </Portal>
      )}
    </>
  );
};

export default Modal;
