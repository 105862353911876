import z from "zod";

export const shapes = {
    GenerateLoginCodeCommand: z.object(
        {
            email: z.string().email(),
        }
    ),
    GenerateLoginCodeResponse: z.object(
        {
            email: z.string().email(),
            sentAt: z.string(),
            debugCode: z.string().optional(),
        }
    ),

    UseLoginCodeCommand: z.object(
        {
            email: z.string().email(),
            code: z.string().length(6),
        }
    ),
    UseLoginCodeResponse: z.object(
        {
            accessToken: z.string(),
        }
    ),
}

export type UseLoginCodeCommand = z.infer<typeof shapes.UseLoginCodeCommand>
export type UseLoginCodeResponse = z.infer<typeof shapes.UseLoginCodeResponse>
export type GenerateLoginCodeCommand = z.infer<typeof shapes.GenerateLoginCodeCommand>
export type GenerateLoginCodeResponse = z.infer<typeof shapes.GenerateLoginCodeResponse>

