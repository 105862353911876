import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../store';
import { LayoutState, changeView } from '../../../store/layoutSlice';
import Button from '../Button';
import { ReactComponent as ListIcon } from '../../../assets/icons/list.svg';
import { ReactComponent as GridIcon } from '../../../assets/icons/grid.svg';

const ViewToggler = () => {
    const dispatch = useDispatch<AppDispatch>();
    const layoutState: LayoutState = useSelector((state: any) => state.layout);

    useEffect(() => {
        localStorage.setItem('hustleView', layoutState.view);
    }, [layoutState.view]);

    const handleChange = () => {
        dispatch(changeView());
    };

    return (
        <Button onClick={handleChange}>
            {layoutState.view === 'list' ? <GridIcon /> : <ListIcon />}
            <p>{layoutState.view === 'list' ? 'Grid view' : 'List view'}</p>
        </Button>
    );
};

export default ViewToggler;
