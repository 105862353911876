import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../store';
import { addTask, WorkspaceState } from '../../store/workspaceSlice';
import { LayoutState } from '../../store/layoutSlice';
import { ProjectsArchive } from './ProjectsArchive';
import { AddProject } from './AddProject';
import Task from '../../layout/atoms/Task';
import { WorkspaceId } from '../../core/model';
import Container from '../../layout/atoms/Container/Container';
import { AddTask } from './AddTask';
import styles from '../../layout/molecules/Project/Project.module.scss';
import ProjectsList from '../../layout/organisms/ProjectsList/ProjectsList';

const allColors = [
    '#ea5545',
    '#f46a9b',
    '#ef9b20',
    '#edbf33',
    '#ede15b',
    '#bdcf32',
    '#87bc45',
    '#27aeef',
    '#b33dc6',
];

const WorkspaceTasks = (props: { workspaceId: WorkspaceId }) => {
    const dispatch = useDispatch<AppDispatch>();
    const workspaceData: WorkspaceState = useSelector(
        (state: any) => state.workspace
    );
    let filteredTasks = workspaceData.tasks.filter((t) => t.state !== 'DONE');

    const [isAddingTask, setIsAddingTask] = useState<boolean>(false);

    const onWorkspaceTasksAdded = async (tasks: string[]) => {
        if (tasks && tasks.length > 0) {
            for (let i = 0; i < tasks.length; i++) {
                const t = tasks[i];
                await dispatch(
                    addTask({
                        workspaceId: props.workspaceId,
                        projectId: props.workspaceId,
                        name: t,
                        planned: false,
                    })
                );
            }
        }
    };

    return (
        <div className={styles.wrap} style={{ margin: '0 auto', padding: '0' }}>
            <div className={styles.main}>
                {filteredTasks.map((t) => (
                    <Task
                        task={t}
                        projectRef={{
                            workspaceId: workspaceData.id,
                            projectId: workspaceData.id,
                        }}
                    ></Task>
                ))}
            </div>
            <div className={styles.footer}>
                <AddTask
                    placeholder={'Add a task to your workspace'}
                    onTasksAdded={onWorkspaceTasksAdded}
                />
            </div>
        </div>
    );
};

const getRandomNonTakenColor = (takenColors: string[]): string => {
    const availableColors = allColors.filter(
        (color) => !takenColors.includes(color)
    );

    if (availableColors.length === 0) {
        const randomRed = Math.floor(Math.random() * 256);
        const randomGreen = Math.floor(Math.random() * 256);
        const randomBlue = Math.floor(Math.random() * 256);
        return `rgb(${randomRed}, ${randomGreen}, ${randomBlue})`;
    }

    const randomIndex = Math.floor(Math.random() * availableColors.length);
    return availableColors[randomIndex];
};

const Plan = () => {
    const dispatch = useDispatch<AppDispatch>();
    const layoutState: LayoutState = useSelector((state: any) => state.layout);
    const workspaceData: WorkspaceState = useSelector(
        (state: any) => state.workspace
    );
    let archivedProjectsCount = useSelector(
        (state: any) => state.workspace.archivedProjectsCount
    );
    const [displayArchive, setDisplayArchive] = useState<boolean>(false);
    const projectsListRef = useRef<any>();

    return (
        <>
            <Container narrow>
                <WorkspaceTasks workspaceId={workspaceData.id} />
            </Container>
            <ProjectsList />
            <Container narrow>
                {workspaceData.projects.length === 0 && (
                    <div className={'empty-state'}>
                        <small>
                            You can organize your tasks into projects,
                            <br /> which are more manageable and effectively
                            encompass work units.
                        </small>
                    </div>
                )}
                <AddProject
                    workspaceId={workspaceData.id}
                    availableColorProvider={() =>
                        getRandomNonTakenColor(
                            workspaceData.projects
                                .filter((p) => !p.color)
                                .map((p) => p.color) as string[]
                        )
                    }
                ></AddProject>
            </Container>
            <Container narrow>
                {archivedProjectsCount > 0 && !displayArchive && (
                    <div
                        className={'container'}
                        style={{ marginTop: '24px', opacity: '0.4' }}
                    >
                        <span
                            style={{ cursor: 'pointer' }}
                            onClick={() => setDisplayArchive(true)}
                        >
                            show archived projects ({archivedProjectsCount})
                        </span>
                    </div>
                )}
                {archivedProjectsCount > 0 && displayArchive && (
                    <div className={'container'} style={{ marginTop: '24px' }}>
                        <div
                            style={{
                                cursor: 'pointer',
                                marginBottom: '24px',
                                opacity: '0.4',
                            }}
                            onClick={() => setDisplayArchive(false)}
                        >
                            hide archived projects
                        </div>
                        <ProjectsArchive />
                    </div>
                )}
            </Container>
        </>
    );
};

export default Plan;
