import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../store';
import { fetchWorkspace } from '../store/workspaceSlice';
import { LayoutState } from '../store/layoutSlice';
import Execute from './execute/Execute';
import Plan from './plan/Plan';
import { WorkspaceId } from '../core/model';
import InitLoader from '../layout/molecules/InitLoader';

type WorkspaceState = 'EXECUTE' | 'PLAN';

const Workspace = (props: { workspaceId: WorkspaceId }) => {
    const dispatch = useDispatch<AppDispatch>();
    const workspaceData = useSelector((state: any) => state.workspace);
    const layoutState: LayoutState = useSelector((state: any) => state.layout);
    const [view, setView] = useState<WorkspaceState>('EXECUTE');

    const workspaceId = props.workspaceId;

    const handleSwitch = (event: any) => {
        setView(event);
    };

    const handleKeyDown = (event: any) => {
        let setViewTo: WorkspaceState | undefined = undefined;
        if (event.key === '1') {
            setViewTo = 'PLAN';
        } else if (event.key === '2') {
            setViewTo = 'EXECUTE';
        }
        if (setViewTo) {
            setView(setViewTo);
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    useEffect(() => {
        if (workspaceId) {
            dispatch(fetchWorkspace(workspaceId));
        }
    }, [workspaceId]);

    useEffect(() => {
        layoutState.view === 'list'
            ? document.body.classList.remove('grid-view')
            : document.body.classList.add('grid-view');
    }, [layoutState.view]);

    useEffect(() => {
        layoutState.theme === 'light'
            ? document.body.classList.remove('dark-theme')
            : document.body.classList.add('dark-theme');
    }, [layoutState.theme]);

    if (workspaceData.status === 'FAILED') {
        return (
            <div style={{ margin: '32px' }}>
                <p>Nieznany bląd aplikacji: {workspaceData.error.errorCode}</p>
            </div>
        );
    }

    return (
        <>
            <div className={'tabs'}>
                <div
                    className={`tabs__tab ${
                        view === 'PLAN' && `tabs__tab--active`
                    }`}
                    onClick={() => handleSwitch('PLAN')}
                >
                    <p>Plan</p>
                </div>
                <div
                    className={`tabs__tab ${
                        view === 'EXECUTE' && `tabs__tab--active`
                    }`}
                    onClick={() => handleSwitch('EXECUTE')}
                >
                    <p>Execute</p>
                </div>
            </div>

            {(workspaceData.status === 'IDLE' ||
                workspaceData.status === 'LOADING') && <InitLoader />}

            {view === 'PLAN' && workspaceData.status === 'SUCCEEDED' && (
                <Plan />
            )}
            {view === 'EXECUTE' && workspaceData.status === 'SUCCEEDED' && (
                <div className={'container'}>
                    <Execute workspaceId={workspaceId} />
                </div>
            )}
        </>
    );
};

export default Workspace;
