import { useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';

export default function Portal(props: {
  children: any;
  parent?: any;
  className?: string;
}) {
  const el = useMemo(() => document.createElement('div'), []);
  useEffect(() => {
    const target = document.body;
    const classList = ['portal-container'];
    if (props.className)
      props.className.split(' ').forEach((item) => classList.push(item));
    classList.forEach((item) => el.classList.add(item));
    target.appendChild(el);
    return () => {
      target.removeChild(el);
    };
  }, [el, props.className]);
  return ReactDOM.createPortal(props.children, el);
}
