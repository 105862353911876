import { useEffect, useRef } from 'react';
import RICIBs from 'react-individual-character-input-boxes';
import styles from './inputCode.module.scss';

const InputCode = (props: {
    value: string;
    placeholder?: string;
    maxLength?: number;
    handleChange?: any;
    handleKeyDown?: any;
    handleBlur?: any;
}) => {
    const backdrop = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const { current } = backdrop;
        const removeUnwantedCharacters = (event: any) => {
            let paste = event.clipboardData.getData('text');
            paste = paste.replace('-', '').replace(/ /g, '').substring(0, 6);

            const selection = window.getSelection();
            if (!selection) return false;
            selection.deleteFromDocument();
            if (current) {
                const inputs = Array.prototype.slice.call(
                    current.getElementsByTagName('input')
                );
                inputs.forEach((input, index) => {
                    input.value = paste[index];
                });
                current.value = paste;
            }
            event.preventDefault();
        };

        if (current) {
            current.addEventListener('paste', removeUnwantedCharacters);
        }

        return () => {
            if (current) {
                current.removeEventListener('paste', removeUnwantedCharacters);
            }
        };
    }, []);

    return (
        <div className={styles.wrap} onBlur={props.handleBlur} ref={backdrop}>
            <RICIBs
                amount={props.maxLength}
                autoFocus
                handleOutputString={props.handleChange}
                inputProps={{
                    className: '2fa-box',
                    placeholder: '0',
                }}
                inputRegExp={/^[a-zA-Z0-9]*$/}
            />
        </div>
    );
};

export default InputCode;
