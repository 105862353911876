import React, { useState } from 'react';

import styles from './task.module.scss';
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash.svg';
import { ProjectRef, TaskAction, TaskId, TaskState } from '../../../core/model';
import {
    deleteTask,
    taskAction,
    TaskStateObject,
} from '../../../store/workspaceSlice';
import { AppDispatch } from '../../../store';
import { useDispatch } from 'react-redux';
import { TaskName } from '../../../workspace/TaskName';

const Task = (props: { task: TaskStateObject; projectRef: ProjectRef }) => {
    const [taskState, setTaskState] = useState<TaskState>(props.task.state);
    const [updating, setUpdating] = useState<boolean>(false);

    const dispatch: AppDispatch = useDispatch();

    const doWorkspaceTaskAction = async (action: TaskAction) => {
        return dispatch(
            taskAction({
                taskId: props.task.id as TaskId,
                action,
                command: {
                    ...props.projectRef,
                },
            })
        );
    };

    const taskClicked = (event: any) => {
        if (updating) {
            return;
        }
        if (taskState === 'IDLE') {
            doWorkspaceTaskAction('PLAN').then((r) => setTaskState('PLANNED'));
        } else if (taskState === 'PLANNED') {
            doWorkspaceTaskAction('IDLE').then((r) => setTaskState('IDLE'));
        }
    };

    const trashClicked = () => {
        if (updating) {
            return;
        }
        doDeleteTask();
    };

    const doDeleteTask = () => {
        return dispatch(
            deleteTask({
                taskId: props.task.id as TaskId,
                command: {
                    ...props.projectRef,
                },
            })
        );
    };

    return (
        <div className={`${styles.wrap} ${styles[taskState]}`}>
            <button
                className={`${styles.box} ${styles[taskState]}`}
                onClick={taskClicked}
                style={{
                    alignSelf: 'stretch',
                    alignContent: 'center',
                }}
            >
                <span />
            </button>
            <div
                className={`${styles.text} ${styles[taskState]}`}
                onClick={taskClicked}
            >
                <TaskName name={props.task.name}></TaskName>
            </div>
            <button className={`${styles['button']}`}>
                <TrashIcon onClick={trashClicked} />
            </button>
        </div>
    );
};

export default Task;
