import styles from './textEditable.module.scss';
import { ReactComponent as CheckIcon } from '../../../assets/icons/checkmark.svg';

const TextEditable = (props: {
    value: string;
    placeholder?: string;
    maxLength?: number;
    handleChange?: any;
    handleKeyDown?: any;
    handleBlur?: any;
    onRenameConfirmedButtonClicked?: any;
}) => {
    return (
        <div className={styles.wrap} onBlur={props.handleBlur}>
            <textarea
                value={props.value}
                onChange={props.handleChange}
                onKeyDown={props.handleKeyDown}
                autoFocus={true}
                maxLength={props.maxLength}
                placeholder={props.placeholder}
                style={{
                    width: '100%',
                    maxHeight: '200px',
                    overflowY: 'auto',
                    resize: 'none',
                }}
            />
            <button onMouseDown={props.onRenameConfirmedButtonClicked}>
                <CheckIcon />
            </button>
        </div>
    );
};

export default TextEditable;
