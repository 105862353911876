import z from "zod";

export const shapes = {
    ReadSessionResponse: z.object(
        {
            userId: z.string(),
            workspaceId: z.string(),
            email: z.string().email(),
        }
    ),

}

export type ReadSessionResponse = z.infer<typeof shapes.ReadSessionResponse>

