import axios, {AxiosInstance} from 'axios';
import {Environment} from '../Environment';
import {
    GenerateLoginCodeCommand,
    GenerateLoginCodeResponse,
    shapes as loginShapes,
    UseLoginCodeCommand,
    UseLoginCodeResponse
} from "./login-model";


export default class AuthApi {

    private http: AxiosInstance;

    constructor() {
        this.http = axios.create({
            headers: {
                'Content-type': 'application/json',
            },
        });
    }

    public static defaultApi() {
        return new AuthApi()
    }

    public generateLoginCode = async (command: GenerateLoginCodeCommand): Promise<GenerateLoginCodeResponse> => {
        const parsed = loginShapes.GenerateLoginCodeCommand.parse(command);
        return this.http.post(`${Environment.backendUrl}/auth/code/authorize`, parsed)
            .then(result => loginShapes.GenerateLoginCodeResponse.parse(result.data));
    };

    public useLoginCode = async (command: UseLoginCodeCommand): Promise<UseLoginCodeResponse> => {
        const parsed = loginShapes.UseLoginCodeCommand.parse(command);
        return this.http.post(`${Environment.backendUrl}/auth/code/login`, parsed)
            .then(result => loginShapes.UseLoginCodeResponse.parse(result.data));
    };
}

