import React, { useState, useEffect } from 'react';
import Container from '../Container/Container';
import styles from './Topbar.module.scss';

const Topbar = (props: { active: boolean; children: React.ReactNode }) => {
    const [showMenu, setShowMenu] = useState<boolean>(false);
    const [scrollOnTop, setScrollOnTop] = useState<boolean>(true);

    const toggleMenu = () => {
        let distanceFromTop = window.pageYOffset;

        return function () {
            let newDistanceFromTop = window.pageYOffset;
            if (
                newDistanceFromTop < 50 ||
                distanceFromTop >= newDistanceFromTop
            ) {
                setShowMenu(true);
            } else {
                setShowMenu(false);
            }
            if (newDistanceFromTop < 50) {
                setScrollOnTop(true);
            } else {
                setScrollOnTop(false);
            }
            distanceFromTop = newDistanceFromTop;
        };
    };

    useEffect(() => {
        setTimeout(() => setShowMenu(true), 500);
        window.addEventListener('scroll', toggleMenu());
        return window.removeEventListener('scroll', toggleMenu());
    }, []);

    return (
        <div
            className={`${styles.wrap} ${
                props.active && showMenu && styles.showMenu
            } ${scrollOnTop && styles.scrollOnTop}`}
        >
            <Container>
                <div className={`${styles.content}`}>{props.children}</div>
            </Container>
        </div>
    );
};

export default Topbar;
